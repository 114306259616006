import { InMemoryCache, ApolloClient, from, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";


const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message }) =>
        console.error("danger", `[GraphQL Error]: ${message}`)
      );

    if (networkError) {
      console.error("danger", `[Network Error]: ${networkError}`);
    }
    return forward(operation);
  }
);

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 5000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API}/graphql`,
});
const middleWares = [cleanTypeName, retryLink, errorLink, uploadLink];

export const client = new ApolloClient({
  link:
    process.env.NODE_ENV === "production"
      ? from([...middleWares])
      : from([...middleWares]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});
