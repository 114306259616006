import { useEffect, useState } from "react";
import * as anchor from "@project-serum/anchor";

import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { PublicKey } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-material-ui";

import { AlertState } from "./utils";

import Header from "./images/header.png";
import TinyPup1 from "./images/469.png";
import TinyPup2 from "./images/470.png";
import TinyPup3 from "./images/475.png";
import TinyPup4 from "./images/481.png";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
}

const GetAllMints = gql`
  query GetAllMints {
    getAllMints {
      id
      mintId
      createdAt
    }
  }
`;

const legendaries = [
  "EEGerbTiqCErqcVvzj6CsVKYGtNdQKqAUdURGk78EW6N",
  "FCNbXrTscKWGNMFdYRJMXTnEfBAi4DbxmuYg4uNQdsJP",
  "Hq61UmvkUAZwweS4dF8KQAfJhzSJoUiBg4y7DXWEqdCx",
  "4kWr4TAp167NeABd6cW4zLX1wrmgT5WzNeTMhNt2sKcd",
  "2sENMoNCktqeRWD1CktMvStmX5FZg8E422Qo1bxY1j2U",
  "5fxjZ5i5Z64hPv4PqtpyLowxEQrtb9N5WqbhSyXThskk",
  "CezMd4rfnWfJ7bF74tLgYD1oMXqrDBaGjph1RHPsBEfw",
  "J9fYu31ExoNWF23NES9gKTjuP6cEgRzoHWwD4EDSxsTi",
  "CxKAQ8YsYpDSEa73f5GUHGSMSWiQoCtP4Q3wcg1HxrFZ",
  "22DNvLDDSYGJgkcH4NLeapFqzKoZ1uPQ5xyGwBH1CtaH",
  "DpTmvQQMGM3uZNF6aUiSJ4nK4aCVGPxnTxP1E57AyYRY",
  "6ikXtxWppawngbVgBzcuXpUyrLfYBw5Ndhc67zkm9xa2",
  "7KdiJapoGq4SeHkXvCrdyJFe4a3EKxianZRVB8QdjRC1",
  "6RkDVjqcJ1ZWDKyMHeZpEEcKdHRtpaN25sYFMLoKqkRk",
  "2m3r9wqs4n5pe4fjCz7dNuDp5BxjNHQPgnA8VWNzZmB9",
  "DBAx8MgAGJCCujNUpsiCNwNEMT81Y8wTUmrxy3RfurLk",
  "wCH9Y6ghvKcFWrP7DKJaX4WogFhMNjqNcEU7xt6WAVS",
  "DLGXDXhy5ptzmXjSPfrNe2ajXZrJfS3QTxsFF24g1SeM",
  "9ZaxwXYBrfLQXiyL7MhsBTkdW57nF23sQqd3utnHM7tx",
  "GoajGMkTaBXGeXPk7GgCNLG4LKsL9KGpJwAXWyGQaoqW",
  "7WaUDmmci9i6Lxnim8FaREHw4smttUXw2rjbDe5E9sBN",
  "3hN3Mo96dK4EiBsRiZvDgiphHSKf9xiQGg8KuKsX4UWf",
  "7T6udCm357aSrpuNLjELsMqm8soJtCHSfjjgkgXssnCP",
  "86x65qL4TmbbYqEdh4NdAJUSjGiV3estREfwTeULwm3e",
  "2woeJrFMFv5ADSNWpTbKSLHEN6dTjbj8aFoYNvAKdZp9",
  "AnWmN2QveuSD6eYSMuwhsqr4WMdRtDydYdSH5bJAZMQ9",
  "4kWr4TAp167NeABd6cW4zLX1wrmgT5WzNeTMhNt2sKcd",
];

const Home = (props: HomeProps) => {
  const { data } = useQuery(GetAllMints);

  const { connection } = useConnection();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const wallet = useWallet();

  useEffect(() => {
    (async () => {
      if (wallet.publicKey) {
        const walletNfts = await Metadata.findDataByOwner(
          connection,
          wallet.publicKey
        );

        const nfts = [];
        for (let nft of walletNfts)
          if (
            nft.data.creators &&
            nft.data.creators[0]?.verified &&
            nft.data.creators[0]?.address ===
              process.env.REACT_APP_CREATOR_ID &&
            data?.getAllMints.some((data) => data.mintId === nft.mint) ===
              false &&
            !legendaries.some((data) => data === nft.mint)
          )
            nfts.push({
              mint: new PublicKey(nft.mint),
              data: nft.data,
              json: await fetch(nft.data.uri).then((e) => e.json()),
            });

        let collator = new Intl.Collator(undefined, { numeric: true });
        nfts.sort((a, b) => collator.compare(a.data.name, b.data.name));
      }
    })();
  }, [connection, data?.getAllMints, wallet, wallet.publicKey]);

  useEffect(() => {
    (async () => {
      if (wallet.publicKey) {
        // setLoading(true);
        const walletNfts = await Metadata.findDataByOwner(
          connection,
          wallet.publicKey
        );

        const nfts = [];
        for (let nft of walletNfts)
          if (
            nft.data.creators &&
            nft.data.creators[0]?.verified &&
            nft.data.creators[0]?.address ===
              process.env.REACT_APP_CREATOR_ID &&
            data?.getAllMints.some((data) => data.mintId === nft.mint)
          )
            nfts.push({
              mint: new PublicKey(nft.mint),
              data: nft.data,
              json: await fetch(nft.data.uri).then((e) => e.json()),
            });

        let collator = new Intl.Collator(undefined, { numeric: true });
        nfts.sort((a, b) => collator.compare(a.data.name, b.data.name));
        // setLoading(false);
      }
    })();
  }, [connection, data?.getAllMints, wallet.publicKey]);

  return (
    <div>
      <div>
        <HeaderContainer>
          <img src={Header} alt={"Tiny Dogz"} />
          <HeaderWalletButton>
            <WalletMultiButton />
          </HeaderWalletButton>
        </HeaderContainer>
        <TinyDogzInfoWrapper>
          <TinyDogzInfoContainer>
            <p>
              Adoption is closed, please find Tiny Pupz on the secondary market{" "}
              <a href="https://magiceden.io/marketplace/tiny_pupz">here...</a>
            </p>
            <TinyDogzInfoGrid>
              <div>
                <img src={TinyPup2} alt={"Tiny Pup 2"} />
              </div>
              <div>
                <img src={TinyPup4} alt={"Tiny Pup 4"} />
              </div>
              <div>
                <img src={TinyPup1} alt={"Tiny Pup 1"} />
              </div>
              <div>
                <img src={TinyPup3} alt={"Tiny Pup 3"} />
              </div>
            </TinyDogzInfoGrid>
          </TinyDogzInfoContainer>
        </TinyDogzInfoWrapper>
      </div>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Home;

const HeaderContainer = styled.div`
  margin: 0 auto 25px;
  /* max-width: 400px; */
  padding: 75px 16px 0;
  text-align: center;

  @media only screen and (min-width: 768px) {
    margin: 0 auto 50px;
    padding: 100px 0 0;
  }

  img {
    max-width: 720px;
    width: 100%;
  }
`;

const HeaderWalletButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const TinyDogzInfoWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  @media only screen and (min-width: 768px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const TinyDogzInfoContainer = styled.div`
  align-items: center;
  background: #ffffff;
  border: 4px solid #e1a7ca;
  margin: 0 auto 100px;
  max-width: 1090px;
  padding: 10px 16px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding: 25px 50px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;

    @media only screen and (min-width: 768px) {
      font-size: 24px;
      margin-bottom: 20px;
    }

    strong {
      font-weight: 600;
    }

    img {
      display: none;
      left: 6px;
      position: relative;
      top: 0px;

      @media only screen and (min-width: 768px) {
        display: inline;
      }
    }
  }
`;

const TinyDogzInfoGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  @media only screen and (min-width: 768px) {
    grid-gap: 20px;
  }

  img {
    width: 100%;
  }
`;
